import { DM_Sans } from 'next/font/google';
import {
  type Breakpoint,
  experimental_extendTheme as extendTheme,
  lighten,
  darken
} from '@mui/material/styles';
import { Theme } from '@mui/material/styles';

import { deepmerge } from '@mui/utils';

import generateGridStyles from './mixins/generateGridStyles';
import applyBackgroundColor from './mixins/applyBackgroundColor';
import applyColorSchemeOverlay from './mixins/applyColorSchemeOverlay';
import themeComponents from './theme.components';

import './theme.types';

export const dmSans = DM_Sans({
  weight: ['300', '400', '500', '700'],
  subsets: ['latin'],
  display: 'swap'
});

export const mainColors = ['navy'];

const defaultSpacing = 8;
const defaultBorderRadius = 4;

const commonColors = {
  black: '#00030B',
  white: '#ffffff',
  gray1: '#f8f8f8',
  gray2: '#E5E6E8',
  gray3: '#BBC5CB',
  gray4: '#7D909B',
  gray5: '#3C586A',
  navy: '#000080',
  transparentLight: 'rgba(0, 0, 0, 0)',
  transparentDark: 'rgba(255, 255, 255, 0)'
};

const schemes = {
  transparentLight: {
    primary: {
      main: commonColors.transparentLight,
      contrastText: commonColors.black,
      light: lighten(commonColors.transparentLight, 0.2),
      dark: darken(commonColors.transparentLight, 0.2)
    },
    secondary: {
      main: commonColors.gray2,
      contrastText: commonColors.black,
      light: lighten(commonColors.gray2, 0.2),
      dark: darken(commonColors.gray2, 0.2)
    },
    linkColor: commonColors.black,
    headerColor: commonColors.black,
    text: commonColors.black,
    overlay: commonColors.black,
    overlayText: commonColors.white,
    highlightColor: commonColors.gray2
  },
  transparentDark: {
    primary: {
      main: commonColors.transparentDark,
      contrastText: commonColors.white,
      light: lighten(commonColors.transparentDark, 0.2),
      dark: darken(commonColors.transparentDark, 0.2)
    },
    secondary: {
      main: commonColors.gray2,
      contrastText: commonColors.white,
      light: lighten(commonColors.gray2, 0.2),
      dark: darken(commonColors.gray2, 0.2)
    },
    linkColor: commonColors.white,
    headerColor: commonColors.white,
    text: commonColors.white,
    overlay: commonColors.gray2,
    overlayText: commonColors.black,
    highlightColor: commonColors.gray2
  },
  black: {
    primary: {
      main: commonColors.black,
      contrastText: commonColors.white,
      light: lighten(commonColors.black, 0.2),
      dark: darken(commonColors.black, 0.2)
    },
    secondary: {
      main: commonColors.navy,
      contrastText: commonColors.white,
      light: lighten(commonColors.navy, 0.2),
      dark: darken(commonColors.navy, 0.2)
    },
    linkColor: commonColors.white,
    headerColor: commonColors.white,
    text: commonColors.white,
    overlay: commonColors.white,
    overlayText: commonColors.white,
    highlightColor: commonColors.navy
  },
  dark: {
    primary: {
      main: commonColors.black,
      contrastText: commonColors.white,
      light: lighten(commonColors.black, 0.2),
      dark: darken(commonColors.black, 0.2)
    },
    secondary: {
      main: commonColors.navy,
      contrastText: commonColors.white,
      light: lighten(commonColors.navy, 0.2),
      dark: darken(commonColors.navy, 0.2)
    },
    linkColor: commonColors.white,
    headerColor: commonColors.white,
    text: commonColors.white,
    overlay: commonColors.white,
    overlayText: commonColors.white,
    highlightColor: commonColors.navy
  },
  white: {
    primary: {
      main: commonColors.white,
      contrastText: commonColors.black,
      light: lighten(commonColors.white, 0.2),
      dark: darken(commonColors.white, 0.2)
    },
    secondary: {
      main: commonColors.navy,
      contrastText: commonColors.white,
      light: lighten(commonColors.navy, 0.2),
      dark: darken(commonColors.navy, 0.2)
    },
    linkColor: commonColors.black,
    headerColor: commonColors.black,
    text: commonColors.black,
    overlay: commonColors.black,
    overlayText: commonColors.black,
    highlightColor: commonColors.navy
  },
  light: {
    primary: {
      main: '#1c73ad',
      contrastText: commonColors.black,
      light: '#0088d8',
      dark: '#003f5d'
    },
    secondary: {
      main: '#c62328',
      contrastText: commonColors.white,
      light: lighten(commonColors.navy, 0.2),
      dark: darken(commonColors.navy, 0.2)
    },
    linkColor: commonColors.black,
    headerColor: commonColors.black,
    text: commonColors.black,
    overlay: commonColors.black,
    overlayText: commonColors.black,
    highlightColor: commonColors.navy,
    utilitarian: {
      lightGray: '#f6f7f8',
      midGray: '#dcdfe2',
      yellow: '#fff3ce'
    },
    red: {
      main: '#C62328',
      light: '#F26529',
      dark: '#C62328',
      contrastText: '#fff'
    },
    orange: {
      main: '#F26529',
      light: '#C62328',
      dark: '#F26529',
      contrastText: '#fff'
    },
    lightBlue: {
      main: '#10ACE3',
      light: '#0072AB',
      dark: '#10ACE3',
      contrastText: '#fff'
    },
    Gray: {
      main: '#58595B',
      light: '#b0b0b0',
      dark: '#404040',
      contrastText: '#fff'
    },
    darkBlue: {
      main: '#0072AB',
      light: '#10ACE3',
      dark: '#0072AB',
      contrastText: '#fff'
    },
    black: {
      main: '#000',
      light: '#58595B',
      dark: '#000',
      contrastText: '#fff'
    },
    white: {
      main: '#fff',
      contrastText: '#000'
    },
    yellow: {
      main: '#FFF200',
      light: '#F26529',
      dark: '#FFC40C',
      contrastText: '#fff'
    },
    yellowBiz: {
      main: '#fdb813',
      light: '#F26529',
      dark: '#FFC40C',
      contrastText: '#fff'
    },
    grey: {
      50: '#f2f2f2',
      100: '#F6F7F8',
      300: '#DCDFE2',
      600: '#58595B',
      700: '#4d4f53'
    }
  },
  navy: {
    primary: {
      main: commonColors.navy,
      contrastText: commonColors.white,
      light: lighten(commonColors.navy, 0.2),
      dark: darken(commonColors.navy, 0.2)
    },
    secondary: {
      main: commonColors.gray2,
      contrastText: commonColors.white,
      light: lighten(commonColors.gray2, 0.2),
      dark: darken(commonColors.gray2, 0.2)
    },
    linkColor: commonColors.white,
    headerColor: commonColors.white,
    text: commonColors.white,
    overlay: commonColors.white,
    overlayText: commonColors.black,
    highlightColor: commonColors.navy
  }
};

export const breakpointsMinMax: Record<string, { min: number; max: number }> = {
  xs: { min: 0, max: 576 },
  sm: { min: 576, max: 834 },
  md: { min: 834, max: 1024 },
  lg: { min: 1024, max: 1440 },
  xl: { min: 1440, max: 1550 },
  xxl: { min: 1550, max: 1920 },
  xxxl: { min: 1920, max: 3840 }
};

const paletteTheme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 834,
      lg: 1024,
      xl: 1440,
      xxl: 1550,
      xxxl: 1920
    }
  },
  colorSchemes: {
    light: {
      palette: {
        schemes: schemes,
        ...schemes['light'],
        text: {
          primary: schemes['light'].text,
          secondary: schemes['light'].text
        }
      }
    },
    dark: {
      palette: {
        schemes: schemes,
        ...schemes['dark'],
        text: {
          primary: schemes['dark'].text,
          secondary: schemes['dark'].text
        }
      }
    }
  }
};

const muiTheme = extendTheme(paletteTheme);

const baseTheme = {
  ...paletteTheme,
  spacing: defaultSpacing,
  shape: {
    borderRadius: defaultBorderRadius
  },
  mixins: {
    generateGridStyles,
    applyBackgroundColor,
    applyColorSchemeOverlay
  },
  typography: {
    'fontFamily': ['Avenir Next', 'sans-serif'].join(','),
    'minHeight': '1em',
    'h1': {
      fontSize: 64,
      lineHeight: 1.04,
      fontWeight: 600,
      letterSpacing: -1,
      fontStyle: 'normal',
      display: 'block',
      minHeight: '1em'
    },
    'h2': {
      fontSize: 36,
      lineHeight: 1.04,
      fontWeight: 600,
      letterSpacing: -1,
      fontStyle: 'normal',
      display: 'block',
      minHeight: '1em'
    },
    'h3': {
      fontSize: 20,
      lineHeight: 1.24,
      fontWeight: 600,
      fontStyle: 'normal',
      display: 'block',
      minHeight: '1em'
    },
    'h4': {
      fontSize: 14,
      lineHeight: 1.24,
      fontWeight: 600,
      fontStyle: 'normal',
      display: 'block',
      minHeight: '1em'
    },
    'h5': {
      fontSize: 10,
      lineHeight: 0.825,
      fontWeight: 600,
      fontStyle: 'normal',
      display: 'block',
      minHeight: '1em'
    },
    'body1': {
      fontSize: 20,
      lineHeight: 1.32,
      fontWeight: 400,
      fontStyle: 'normal',
      minHeight: '1em'
    },
    'body2': {
      fontSize: 16,
      lineHeight: 1.32,
      fontWeight: 400,
      fontStyle: 'normal',
      minHeight: '1em'
    },
    'body3': {
      fontSize: 14,
      lineHeight: 1.155,
      fontWeight: 400,
      fontStyle: 'normal',
      minHeight: '1em'
    },
    'body4': {
      fontSize: 12,
      lineHeight: 1.32,
      fontWeight: 400,
      fontStyle: 'normal',
      display: 'block',
      minHeight: '1em'
    },
    'Pipe Divider': {
      fontSize: 20,
      lineHeight: 2,
      fontWeight: 400,
      fontStyle: 'normal',
      color: '#1c73ad',
      minHeight: '1em'
    }
  },
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        label: ({ theme }) => ({
          color: theme.palette.common.white
        })
      }
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        variant: 'filled',
        InputProps: {
          disableUnderline: true
        }
      },
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiFilledInput-root': {
            'border': '1px solid #e2e2e1',
            'overflow': 'hidden',
            'borderRadius': 4,
            'backgroundColor': theme.palette.common.white,
            'transition': theme.transitions.create([
              'border-color',
              'background-color',
              'box-shadow'
            ]),
            '&:hover': {
              backgroundColor: theme.palette.common.white
            },
            '&.Mui-focused': {
              backgroundColor: theme.palette.common.white,
              borderColor: theme.palette.primary.main
            }
          },
          '& .MuiFilledInput-input': {
            paddingTop: theme.spacing(0.625)
          }
        })
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        filled: () => ({
          transform: 'translate(12px, 8px) scale(1);'
        })
      }
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: 'lg'
      },
      styleOverrides: {
        root: ({ theme }) => ({
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: theme.spacing(2),
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),

          [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(8, 1fr)',
            paddingLeft: theme.spacing(7),
            paddingRight: theme.spacing(7)
          },

          [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: 'repeat(12, 1fr)'
          },

          [theme.breakpoints.up('xl')]: {
            maxWidth: 1440,
            gap: theme.spacing(3),
            paddingLeft: theme.spacing(12),
            paddingRight: theme.spacing(12)
          }
        })
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: '0 !important',
          border: 'none !important'
        },
        input: ({ theme }) => ({
          ...theme.typography.body4,
          minHeight: '32px !important',
          boxSizing: 'border-box',

          [theme.breakpoints.down('md')]: {
            minHeight: '24px !important'
          }
        })
      }
    },
    MuiButton: {
      defaultProps: {
        disableFocusRipple: true,
        disableTouchRipple: true,
        disableElevation: true
      },
      styleOverrides: {
        root: ({ theme }) => ({
          'textTransform': 'none',
          '&:focus-visible': {
            boxShadow: `0px 0px 0px 2px ${theme.vars.palette.common.white}`,
            outlineOffset: 2
          }
        }),
        sizeSmall: ({ theme }) => ({
          ...theme.typography.h5,
          height: theme.spacing(4),
          padding: theme.spacing(1)
        }),
        sizeMedium: ({ theme }) => ({
          ...theme.typography.h4,
          height: theme.spacing(5),
          padding: theme.spacing(1),
          borderRadius: Number(theme.shape.borderRadius) * 2
        }),
        sizeLarge: ({ theme }) => ({
          ...theme.typography.h3,
          height: theme.spacing(6),
          padding: theme.spacing(1.5, 1),
          borderRadius: Number(theme.shape.borderRadius) * 2
        }),
        endIcon: ({ theme }) => ({
          marginLeft: theme.spacing(2)
        })
      },
      variants: []
    }
  },
  containerBreakpoints: {
    ...muiTheme.breakpoints,
    up: (key: Breakpoint | number) => {
      return muiTheme.breakpoints.up(key)?.replace('@media', '@container');
    },
    down: (key: Breakpoint | number) => {
      return muiTheme.breakpoints.down(key)?.replace('@media', '@container');
    }
  }
};

// @ts-expect-error
const coreTheme = extendTheme(baseTheme);

const typedThemeComponents = themeComponents as Record<
  string,
  (theme: Theme) => { components: Record<string, any> }
>;

export const theme = extendTheme(
  // @ts-expect-error
  deepmerge(baseTheme, {
    components: Object.values(typedThemeComponents)
      .map((t) => t(coreTheme))
      .reduce((acc, current) => {
        return { ...acc, ...current.components };
      }, {} as Record<string, any>)
  })
);

export const breakpoints = theme.breakpoints.values;
