import type {
  Theme,
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';

// https://mui.com/customization/theme-components/#default-props
export const defaultProps: ComponentsProps['Header'] = {};

// https://mui.com/customization/theme-components/#global-style-overrides
export const styleOverrides: ComponentsOverrides<Theme>['Header'] = {
  root: ({ theme }: { theme: Theme }) => ({
    backgroundColor: theme.palette.common.white,
    marginLeft: 'auto',
    marginRight: 'auto',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.2)',
    position: 'sticky',
    top: 0
  }),
  logo: () => ({
    height: 20,
    width: 'auto'
  }),
  mainContent: ({ theme }: { theme: Theme }) => ({
    width: '100%',
    display: 'grid',
    maxWidth: theme.breakpoints.values.xl,
    marginLeft: 'auto',
    marginRight: 'auto',
    height: 64,
    gridTemplateColumns: 'repeat(7, 1fr)',

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'auto 1fr',
      height: 104
    }
  }),

  logoWrapper: ({ theme }: { theme: Theme }) => ({
    'gridColumn': '1 / 5',
    'width': '100%',
    'display': 'flex',
    'alignItems': 'center',
    'fontSize': 0,

    '& img': {
      maxWidth: 180,
      objectFit: 'contain'
    },

    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / 3',
      height: 48
    }
  }),
  mainToolbar: () => ({
    position: 'relative'
  }),
  rightContent: ({ theme }: { theme: Theme }) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    height: 64,
    width: `${(5 / 7) * 100}%`,
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',

    [theme.breakpoints.down('md')]: {
      position: 'static',
      display: 'block',
      top: 48,
      width: '100%',
      height: 40
    }
  }),
  navItems: ({ theme }: { theme: Theme }) => ({
    'gridColumn': '1 / 3',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'flex-end',
    'gap': theme.spacing(1),
    'paddingRight': theme.spacing(2),

    '[class*=Link-root]': {
      'minWidth': 0,

      ' > div': {
        minWidth: 0
      },

      '&:hover, &.MuiLink-selected': {
        fontWeight: 700
      }
    },

    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }),
  phoneContent: ({ theme }: { theme: Theme }) => ({
    'flexDirection': 'column',
    'alignItems': 'flex-end',
    'justifyContent': 'center',

    '> span': {
      position: 'relative',
      zIndex: 1
    },

    '> a': {
      position: 'relative',
      zIndex: 1,
      ...theme.typography.h4
    },

    [theme.breakpoints.down('lg')]: {
      '> a': {
        ...theme.typography.h5,
        marginTop: theme.spacing(0.5)
      }
    }
  }),
  mobileMenu: ({ theme }: { theme: Theme }) => ({
    [theme.breakpoints.down('md')]: {
      display: 'inline-block',
      height: 40,
      marginLeft: theme.spacing(1)
    }
  }),
  mobileMenuDropdown: ({ theme }: { theme: Theme }) => ({
    '.MuiPaper-root': {
      borderRadius: 0
    },

    'ul': {
      padding: 0,

      a: {
        'display': 'block',
        'backgroundColor': theme.palette.utilitarian.lightGray,
        'color': theme.palette.black.light,
        'padding': theme.spacing(2),
        'height': 'auto',
        'borderRadius': 0,
        'textAlign': 'right',
        'minWidth': 240,

        '&:hover, &.MuiLink-selected': {
          backgroundColor: theme.palette.utilitarian.midGray
        }
      }
    }
  }),
  phoneWrapper: ({ theme, variant }: { theme: Theme; variant?: string }) => {
    return {
      'gridColumn': '3 / 4',
      'display': 'flex',
      'alignItems': 'center',
      'justifyContent': 'flex-end',
      'position': 'relative',
      'paddingRight': theme.spacing(5),

      '&:before': {
        display: 'block',
        content: '""',
        position: 'absolute',
        top: 0,
        left: -14,
        width: '100%',
        height: '100%',
        opacity: 0.2,
        backgroundColor: variant === 'Biz' ? theme.palette.grey[700] : theme.palette.yellow.main,
        transform: 'skewX(-20deg)'
      },

      [theme.breakpoints.down('md')]: {
        'position': 'absolute',
        'right': 0,
        'top': 0,
        'paddingLeft': theme.spacing(3),
        'paddingRight': theme.spacing(1),
        'height': 48,

        '&:before': {
          content: 'none'
        }
      }
    };
  },
  formWrapper: ({ theme, variant }: { theme: Theme; variant?: string }) => ({
    'gridColumn': '4 / 6',
    'position': 'relative',
    'paddingRight': theme.spacing(5),

    '&:before': {
      display: 'block',
      content: '""',
      position: 'absolute',
      top: 0,
      left: -14,
      width: '100%',
      height: '100%',
      backgroundColor: variant === 'Biz' ? theme.palette.darkBlue.main : theme.palette.yellow.dark,
      transform: 'skewX(-20deg)'
    },

    '&:after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      width: 64,
      height: '100%',
      backgroundColor: variant === 'Biz' ? theme.palette.darkBlue.main : theme.palette.yellow.dark
    },

    [theme.breakpoints.down('md')]: {
      'position': 'absolute',
      'top': 48,
      'left': 0,
      'right': 0,
      'height': 56,
      'backgroundColor':
        variant === 'Biz' ? theme.palette.darkBlue.main : theme.palette.yellow.dark,
      'paddingRight': theme.spacing(3),

      '&:before': {
        content: 'none'
      },

      '&:after': {
        content: 'none'
      }
    }
  }),
  formContent: ({ theme }: { theme: Theme }) => ({
    position: 'absolute',
    top: 0,
    left: -28,
    right: 0,
    zIndex: 1,

    [theme.breakpoints.down('md')]: {
      right: 0,
      left: 'auto'
    },

    [theme.breakpoints.down('sm')]: {
      left: 0
    }
  })
};

// https://mui.com/customization/theme-components/#adding-new-component-variants
const createVariants = (_theme: Theme): ComponentsVariants['Header'] => [];

export const HeaderResidentialTheme = (theme: Theme): ThemeOptions => ({
  components: {
    Header: {
      // @ts-expect-error
      height: 80,
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default HeaderResidentialTheme;
