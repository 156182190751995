import {
  type Theme,
  type ThemeOptions,
  type ComponentsProps,
  type ComponentsOverrides,
  type ComponentsVariants
} from '@mui/material/styles';

import 'swiper/css';
import 'swiper/css/navigation';

const defaultProps: ComponentsProps['CollectionGallery'] = {};

const styleOverrides: ComponentsOverrides<Theme>['CollectionGallery'] = {
  root: {},

  imageList: ({ theme, ownerState }) => ({
    ...(!!ownerState?.isCarouselMobile && {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    }),

    ...(!!ownerState?.isCarouselTablet && {
      [theme.breakpoints.between('sm', 'lg')]: {
        display: 'none'
      }
    }),

    ...(!!ownerState?.isCarouselDesktop && {
      [theme.breakpoints.up('lg')]: {
        display: 'none'
      }
    })
  }),

  swiperWrap: ({ theme, ownerState }) => ({
    ...(!ownerState?.isCarouselMobile && {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    }),

    ...(!ownerState?.isCarouselTablet && {
      [theme.breakpoints.between('sm', 'lg')]: {
        display: 'none'
      }
    }),

    ...(!ownerState?.isCarouselDesktop && {
      [theme.breakpoints.up('lg')]: {
        display: 'none'
      }
    }),

    '.swiper-slide': {
      height: '25vh',
      maxHeight: '25vh',
      width: 'auto'
    }
  }),

  swiperInnerWrap: {
    img: {
      overflow: 'unset',
      position: 'relative',
      zIndex: 20
    }
  }
};

const createVariants = (_theme: Theme): ComponentsVariants['CollectionGallery'] => [];

const collectionGalleryTheme = (theme: Theme): ThemeOptions => ({
  components: {
    CollectionGallery: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default collectionGalleryTheme;
