import type {
  Theme,
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';

// https://mui.com/customization/theme-components/#default-props
export const defaultProps: ComponentsProps['OfferDiscount'] = {
  // contentWidth: 'xl'
};

// https://mui.com/customization/theme-components/#global-style-overrides
export const styleOverrides: ComponentsOverrides<Theme>['OfferDiscount'] = {
  root: ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(3),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.orange.main,
    color: theme.palette.common.white,
    position: 'sticky',
    top: 72,
    zIndex: 1000,

    [theme.breakpoints.down('lg')]: {
      'gap': theme.spacing(2),

      '> h3': {
        ...theme.typography.h4
      },

      '> p': {
        ...theme.typography.body4
      }
    },

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: theme.spacing(0.5),
      top: 112
    }
  }),

  ctaButton: ({ theme }) => ({
    'backgroundColor': theme.palette.common.white,
    'color': theme.palette.orange.main,
    'borderRadius': theme.shape.borderRadius,
    ...theme.typography.h4,

    [theme.breakpoints.down('lg')]: {
      ...theme.typography.h5,
      height: 30
    },

    '&:hover, &:focus, &:active': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.red.main
    }
  })
};

// https://mui.com/customization/theme-components/#adding-new-component-variants
const createVariants = (_theme: Theme): ComponentsVariants['OfferDiscount'] => [];

export default (theme: Theme): ThemeOptions => ({
  components: {
    OfferDiscount: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});
