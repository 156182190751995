import { Theme, ThemeOptions, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles';

// https://mui.com/customization/theme-components/#default-props
export const defaultProps = {};

// https://mui.com/customization/theme-components/#global-style-overrides
export const styleOverrides: ComponentsOverrides<Theme>['Block'] = {
  root: ({ theme, ownerState }) => ({
    'paddingTop': ownerState?.hasBackgroundColor ? theme.spacing(2) : theme.spacing(4),
    'paddingBottom': ownerState?.hasBackgroundColor ? theme.spacing(2) : theme.spacing(4),
    'backgroundRepeat': 'no-repeat',
    'backgroundPosition': 'center',
    'backgroundSize': 'cover',

    '[class*=Section-gridItem] &': {
      'padding': 0,

      '.MuiContainer-root': {
        padding: 0
      }
    },

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },

    [theme.breakpoints.up('lg')]: {
      paddingTop: ownerState?.hasBackgroundColor ? theme.spacing(4) : theme.spacing(8),
      paddingBottom: ownerState?.hasBackgroundColor ? theme.spacing(4) : theme.spacing(8)
    },

    [theme.breakpoints.up('xl')]: {
      paddingTop: ownerState?.hasBackgroundColor ? theme.spacing(4) : theme.spacing(10),
      paddingBottom: ownerState?.hasBackgroundColor ? theme.spacing(4) : theme.spacing(10)
    }
  }),

  title: ({ theme }) => ({
    ...theme.typography.h3,
    color: theme.palette.grey[600],
    marginBottom: theme.spacing(2)
  }),

  content: () => ({
    position: 'relative'
  }),

  contentWrap: ({ theme }) => ({
    color: theme.palette.grey[600],
    display: 'flex',
    gap: theme.spacing(2),

    gridColumn: '1 / -1',
    flexDirection: 'column',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  }),

  logo: () => ({ maxWidth: 300 }),

  mediaWrap: () => ({ lineHeight: 0 }),

  body: () => ({
    '& > *': {
      marginTop: '.5em !important'
    },

    '& >*:first-child': {
      marginTop: '0 !important'
    },

    '& >*:last-child': {
      marginBottom: '0 !important'
    },

    '& [class*=MuiTypography-h]': {
      paddingTop: '.25em'
    },

    '& [class$=PriceItem-discountPrice]': {
      '& > *': {
        paddingTop: '0 !important'
      }
    }
  }),

  contentInnerWrap: ({ theme }) => ({
    flexDirection: 'column',
    display: 'flex',
    paddingBottom: theme.spacing(2)
  }),

  // contentRoot: ({ theme }) => ({
  //   'gridRow': 1,
  //   'position': 'relative',
  //   'zIndex': 1,
  //   'padding': theme.spacing(4, 0),

  //   'gridColumn': '7 / -1',

  //   [theme.breakpoints.down('lg')]: {
  //     gridColumn: '4 / -1'
  //   },

  //   [theme.breakpoints.down('md')]: {
  //     gridRow: 'inherit',
  //     gridColumn: '3 / -1',
  //     padding: theme.spacing(4, 0)
  //   },

  //   [theme.breakpoints.down('sm')]: {
  //     gridColumn: '1 / -1'
  //   },

  //   '[class$=Block-text]': {
  //     'h1, h2, h3, h4, h5': {
  //       marginBottom: theme.spacing(1)
  //     },

  //     'li': {
  //       marginBottom: theme.spacing(1)
  //     }
  //   }
  // }),

  backgroundRoot: ({ theme }) => ({
    position: 'absolute',
    zIndex: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'contain',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
      position: 'static'
    }
  })
};

// https://mui.com/customization/theme-components/#adding-new-component-variants
const createVariants = (theme: Theme): ComponentsVariants['Block'] => [
  {
    props: {
      variant: 'contentBelow'
    },
    style: {
      '[class*=Block-contentWrap]': {
        flexDirection: 'column'
      },

      '[class*=Block-contentInnerWrap]': {
        order: 2
      },

      '[class*=Block-mediaOuterWrap]': {
        order: 1,
        margin: '0 auto',

        img: {
          width: 'auto',
          maxWidth: '100%',
          height: '200px',
          objectFit: 'contain'
        }
      },

      '[class*=Block-actionsWrap]': {
        order: 2
      }
    }
  },
  {
    props: {
      variant: 'mediaLeftTextRight'
    },
    style: {
      '[class*=Block-contentInnerWrap]': {
        order: 2,
        margin: 'auto 0',
        [theme.breakpoints.up('md')]: {
          width: '50%'
        }
      },

      '[class*=Block-mediaOuterWrap]': {
        order: 1,

        img: {
          maxWidth: '100%',
          height: 'auto'
        },

        [theme.breakpoints.up('md')]: {
          width: '50%'
        }
      }
    }
  },
  {
    props: {
      variant: 'textLeftMediaRight'
    },
    style: {
      '[class$=Block-backgroundRoot]': {
        left: 'auto',
        right: 0
      },

      '[class*=Block-contentInnerWrap]': {
        order: 2,
        margin: 'auto 0',

        [theme.breakpoints.up('md')]: {
          order: 1,
          width: '50%'
        }
      },

      '[class*=Block-mediaOuterWrap]': {
        order: 1,

        img: {
          maxWidth: '100%',
          height: 'auto'
        },

        [theme.breakpoints.up('md')]: {
          order: 2,
          width: '50%'
        }
      }
    }
  },
  {
    props: {
      variant: 'circleImageLeftCtaCenter'
    },
    style: {
      '[class*=Block-contentWrap]': {
        display: 'contents'
      },

      '[class*="Block-mediaOuterWrap"]': {
        marginRight: theme.spacing(3),
        gridColumn: '1 / 2',
        gridRow: 1,
        margin: 0,
        justifyContent: 'flex-end',
        alignItems: 'center',

        [theme.breakpoints.up('lg')]: {
          gridColumn: '4 / 6'
        },

        img: {
          // height: theme.spacing(10),
          // width: 'auto',
          height: 'auto',
          width: '100%',
          borderRadius: '50%',
          aspectRatio: '1'
        }
      },

      '[class*=Block-contentInnerWrap]': {
        'gridColumn': '2 / -1',
        'gridRow': 1,

        '& > *:last-child': {
          marginBottom: 0
        },

        [theme.breakpoints.up('lg')]: {
          gridColumn: '6 / 12'
        }
      },

      '[class*=Block-actionsWrap]': {
        margin: 0,
        gridColumn: '2 / -1',
        gridRow: 2,

        [theme.breakpoints.up('lg')]: {
          margin: 0,
          gridColumn: '6 / 12'
        }
      },

      '[class*="Block-contentInnerWrap"]': {
        alignItems: 'center',
        flexDirection: 'row'
      }
    }
  }
];

export default (theme: Theme): ThemeOptions => ({
  components: {
    Block: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});
