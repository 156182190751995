import type {
  Theme,
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';

// https://mui.com/customization/theme-components/#default-props
export const defaultProps: ComponentsProps['PriceItem'] = {
  // contentWidth: 'xl'
};

// https://mui.com/customization/theme-components/#global-style-overrides
export const styleOverrides: ComponentsOverrides<Theme>['PriceItem'] = {
  root: ({ theme }) => ({
    marginBottom: theme.spacing(2)
  }),
  topLine: ({ theme, isPlanCard }) => ({
    position: 'relative',
    display: 'inline-flex',
    marginBottom: theme.spacing(2),
    ...(!!isPlanCard && {
      flexDirection: 'column',
      alignItems: 'center',
      gap: theme.spacing(1)
    })
  }),
  originalPrice: ({ theme, isPlanCard }) => ({
    'position': 'relative',
    'display': 'block',

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: `linear-gradient(to top left,
        rgba(0,0,0,0) 0%,
        rgba(0,0,0,0) calc(50% - 0.8px),
        ${theme.palette.red.main} 50%,
        rgba(0,0,0,0) calc(50% + 0.8px),
        rgba(0,0,0,0) 100%)`
    },

    '> div': {
      display: 'inline-flex',
      fontSize: 0
    },

    '.priceCurrency': {
      ...theme.typography.body4,
      lineHeight: 1
    },

    '.priceDollar': {
      ...theme.typography.body1,
      lineHeight: 1,
      marginTop: 1
    },

    '.priceCents': {
      ...theme.typography.body4,
      lineHeight: 1
    },

    ...(!!isPlanCard && {
      width: 'fit-content'
    })
  }),
  discountPrice: ({ theme }) => ({
    '> div': {
      'display': 'inline-flex',
      'fontSize': 0,
      '[class*=HeroResidential-root] &': {
        color: 'black'
      }
    },

    '.priceCurrency': {
      ...theme.typography.h3,
      lineHeight: 1
    },

    '.priceDollar': {
      ...theme.typography.h1,
      lineHeight: 1,
      marginTop: -5
    },

    '.priceCents': {
      display: 'flex',
      flexDirection: 'column'
    },

    '.priceCentsValue': {
      ...theme.typography.h3,
      lineHeight: 1
    },

    '.priceCentsUnit': {
      ...theme.typography.h5,
      lineHeight: 1.1,
      marginTop: 3,
      marginLeft: 2,
      textAlign: 'left',

      span: {
        display: 'block'
      }
    }
  }),
  previousPriceText: () => ({
    display: 'block'
  })
};

// https://mui.com/customization/theme-components/#adding-new-component-variants
const createVariants = (_theme: Theme): ComponentsVariants['PriceItem'] => [
  {
    props: {
      variant: 'Plan Card'
    },
    style: {
      textAlign: 'center'
    }
  },
  {
    props: {
      variant: 'Hero'
    },
    style: {
      '[class*=PriceItem-discountPrice]': {
        '.priceDollar': {
          fontSize: 100
        },
        '.priceCents': {
          justifyContent: 'flex-end',
          paddingBottom: 20
        }
      }
    }
  }
];

export default (theme: Theme): ThemeOptions => ({
  components: {
    PriceItem: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});
