import type {
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';
import { Theme } from '@ui/ThemeRegistry/theme.types';

const defaultProps: ComponentsProps['Link'] = {};

const styleOverrides: ComponentsOverrides<Theme>['Link'] = {
  root: ({ theme }: { theme: Theme }) => ({
    'display': 'inline-flex',
    'alignItems': 'center',
    'gap': theme.spacing(2),
    'textDecoration': 'none',
    'minWidth': 160,

    '& *': {
      display: 'flex'
    },

    '& .MuiButton-startIcon': {
      margin: 0
    },

    '& .MuiButton-endIcon': {
      margin: 0
    }
  }),
  rootButton: ({ theme }: { theme: Theme }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.spacing(2)
  })
};

const createVariants = (theme: Theme): ComponentsVariants['Link'] => [
  {
    props: {
      variant: 'outlined'
    },
    style: {
      'backgroundColor': 'transparent',
      '&:hover': {
        backgroundColor: 'transparent'
      },
      '&:focus, &:active': {
        backgroundColor: 'transparent'
      },
      '&.Mui-disabled': {
        borderColor: theme.vars.palette.grey[300],
        backgroundColor: theme.vars.palette.grey[100],
        color: theme.vars.palette.grey[300]
      }
    }
  },
  {
    props: {
      variant: 'contained'
    },
    style: {
      'background': theme.vars.palette.red.main, // remove this later, find a proper fix
      '&:hover': {
        backgroundColor: theme.vars.palette.red.light
      },
      '&.Mui-disabled': {
        backgroundColor: theme.vars.palette.grey[300],
        color: theme.vars.palette.red.contrastText
      }
    }
  },
  {
    props: {
      variant: 'text'
    },
    style: {
      'borderColor': 'transparent',
      'backgroundColor': 'transparent',
      '&:hover': {
        backgroundColor: 'transparent'
      },
      '&:focus, &:active': {
        backgroundColor: 'transparent'
      },
      '&.Mui-disabled': {
        borderColor: 'transparent',
        backgroundColor: 'transparent',
        color: theme.vars.palette.grey[300]
      }
    }
  },
  {
    props: {
      variant: 'toggle'
    },
    style: {
      'backgroundColor': theme.vars.palette.grey[300],
      '&:hover': {
        backgroundColor: theme.vars.palette.grey[300]
      },
      '&:focus, &:active': {
        backgroundColor: theme.vars.palette.grey[300]
      },
      '&.Mui-disabled': {
        backgroundColor: theme.vars.palette.grey[100],
        color: theme.vars.palette.grey[300]
      }
    }
  },
  {
    props: {
      color: 'red',
      variant: 'contained'
    },
    style: {
      'backgroundColor': theme.vars.palette.red.main,

      '&:hover': {
        backgroundColor: theme.vars.palette.red.light
      }
    }
  },
  {
    props: {
      color: 'orange',
      variant: 'contained'
    },
    style: {
      'backgroundColor': theme.vars.palette.orange.main,

      '&:hover': {
        backgroundColor: theme.vars.palette.orange.light
      }
    }
  },
  {
    props: {
      color: 'lightBlue',
      variant: 'contained'
    },
    style: {
      'backgroundColor': theme.vars.palette.lightBlue.main,

      '&:hover': {
        backgroundColor: theme.vars.palette.lightBlue.light
      }
    }
  },
  {
    props: {
      color: 'darkBlue',
      variant: 'contained'
    },
    style: {
      'backgroundColor': theme.vars.palette.darkBlue.main,

      '&:hover': {
        backgroundColor: theme.vars.palette.darkBlue.light
      }
    }
  },
  {
    props: {
      color: 'black',
      variant: 'contained'
    },
    style: {
      'backgroundColor': theme.vars.palette.black.main,

      '&:hover': {
        backgroundColor: theme.vars.palette.black.light
      }
    }
  },
  {
    props: {
      color: 'red',
      variant: 'outlined'
    },
    style: {
      borderColor: theme.vars.palette.red.main
    }
  },
  {
    props: {
      color: 'orange',
      variant: 'outlined'
    },
    style: {
      borderColor: theme.vars.palette.orange.main
    }
  },
  {
    props: {
      color: 'lightBlue',
      variant: 'outlined'
    },
    style: {
      borderColor: theme.vars.palette.lightBlue.main
    }
  },
  {
    props: {
      color: 'darkBlue',
      variant: 'outlined'
    },
    style: {
      borderColor: theme.vars.palette.darkBlue.main
    }
  },
  {
    props: {
      color: 'black',
      variant: 'outlined'
    },
    style: {
      borderColor: theme.vars.palette.black.main
    }
  },
  {
    props: {
      color: 'red',
      variant: 'toggle'
    },
    style: {
      color: theme.vars.palette.red.main
    }
  },
  {
    props: {
      color: 'orange',
      variant: 'toggle'
    },
    style: {
      color: theme.vars.palette.orange.main
    }
  },
  {
    props: {
      color: 'lightBlue',
      variant: 'toggle'
    },
    style: {
      color: theme.vars.palette.lightBlue.main
    }
  },
  {
    props: {
      color: 'darkBlue',
      variant: 'toggle'
    },
    style: {
      color: theme.vars.palette.darkBlue.main
    }
  },
  {
    props: {
      color: 'black',
      variant: 'toggle'
    },
    style: {
      color: theme.vars.palette.black.main
    }
  }
];

export const LinkTheme = (theme: Theme): ThemeOptions => ({
  components: {
    Link: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    },
    MuiButton: {
      defaultProps: {},
      styleOverrides: {}
    },

    MuiLink: {
      styleOverrides: {}
    }
  }
});

export default LinkTheme;
