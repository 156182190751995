import dynamic from 'next/dynamic';

const Block = dynamic(() => import('./Block'));
const HomeWifi = dynamic(() => import('./HomeWifi'));
const BizLeadForm = dynamic(() => import('./BizLeadForm'));
const BizLeadFormSmartyStreets = dynamic(() => import('./BizLeadFormSmartyStreets'));
const BroadbandLabel = dynamic(() => import('./BroadbandLabel'));
const Card = dynamic(() => import('./Card'));
const Collection = dynamic(() => import('./Collection'));
const CollectionCarousel = dynamic(() => import('./CollectionCarousel'));
const CollectionGallery = dynamic(() => import('./CollectionGallery'));
const FooterResidential = dynamic(() => import('./FooterResidential'));
const FormCommercialInternet = dynamic(() => import('./FormCommercialInternet'));
const GetOfferButton = dynamic(() => import('./GetOfferButton'));
const Header = dynamic(() => import('./Header'));
const HeaderResidential = dynamic(() => import('./HeaderResidential'));
const HeroCarousel = dynamic(() => import('./HeroCarousel'));
const HeroResidential = dynamic(() => import('./HeroResidential'));
const Link = dynamic(() => import('./Link'));
const Media = dynamic(() => import('./Media'));
const ModuleReviews = dynamic(() => import('./ModuleReviews'));
const ModuleTv = dynamic(() => import('./ModuleTv'));
const OfferDiscount = dynamic(() => import('./OfferDiscount'));
const PageResidential = dynamic(() => import('./PageResidential'));
const PlanCard = dynamic(() => import('./PlanCard'));
const PlanCardResidential = dynamic(() => import('./PlanCardResidential'));
const PriceItem = dynamic(() => import('./PriceItem'));
const Quote = dynamic(() => import('./Quote'));
const ResidentialLeadForm = dynamic(() => import('./ResidentialLeadForm'));
const ResidentialLeadFormSmartyStreets = dynamic(
  () => import('./ResidentialLeadFormSmartyStreets')
);
const Section = dynamic(() => import('./Section'));
const ServiceableNotice = dynamic(() => import('./ServiceableNotice'));
const Text = dynamic(() => import('./Text'));

export const contentMapping: {
  [key: string]: any;
} = {
  Block,
  'Block:homeWiFi': HomeWifi,
  Card,
  Collection,
  'CollectionGallery': CollectionGallery,
  'Collection:carousel': CollectionCarousel,
  'Hero:carousel': HeroCarousel,
  ServiceableNotice,
  'Footer': FooterResidential,
  Header,
  'Header:Biz': HeaderResidential,
  HeaderResidential,
  OfferDiscount,
  HeroResidential,
  Link,
  'Link:button-offer': GetOfferButton,
  Media,
  ModuleTv,
  ModuleReviews,
  'Form': ResidentialLeadForm,
  'Form:Smarty Streets': ResidentialLeadFormSmartyStreets,
  'ModuleIntegration:Commercial Internet Form': FormCommercialInternet,
  'ModuleIntegration:Biz Internet Form': BizLeadForm,
  'ModuleIntegration:Biz Internet Form (Smarty Streets)': BizLeadFormSmartyStreets,
  'ModuleIntegration:Broadband Labels': BroadbandLabel,
  Quote,
  PageResidential,
  PlanCard,
  PriceItem,
  Section,
  Text,
  PlanCardResidential
};

export default contentMapping;
