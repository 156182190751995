import type {
  Theme,
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';

// https://mui.com/customization/theme-components/#default-props
export const defaultProps: ComponentsProps['ServiceableNotice'] = {};

// https://mui.com/customization/theme-components/#global-style-overrides
export const styleOverrides: ComponentsOverrides<Theme>['ServiceableNotice'] = {
  root: ({ theme }: { theme: Theme }) => ({
    '& .MuiDialog-container': {
      '> .MuiPaper-root': {
        borderRadius: 16,
        backgroundColor: theme.palette.utilitarian.yellow
      }
    }
  }),
  title: ({ theme }: { theme: Theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0.5)
  }),
  content: ({ theme }: { theme: Theme }) => ({
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    'gap': theme.spacing(3),
    'padding': `0 ${theme.spacing(6)} ${theme.spacing(6)}`,

    'ul li': {
      'marginBottom': theme.spacing(1),

      '&:before': {
        marginTop: -5
      }
    },

    '[class$=Text-root]': {
      '.MuiTypography-h4, .MuiTypography-body1': {
        textAlign: 'center'
      }
    }
  }),
  closeButton: ({ theme }: { theme: Theme }) => ({
    color: theme.palette.black.main,

    svg: {
      fontSize: 20
    }
  }),
  actions: ({ theme }: { theme: Theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: theme.spacing(2),

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr'
    }
  }),
  actionBox: ({ theme }: { theme: Theme }) => ({
    border: `1px solid ${theme.palette.yellow.dark}`,
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    padding: theme.spacing(1),

    p: {
      color: theme.palette.grey[600],
      ...theme.typography.body4,
      textAlign: 'center'
    }
  })
};

// https://mui.com/customization/theme-components/#adding-new-component-variants
const createVariants = (_theme: Theme): ComponentsVariants['ServiceableNotice'] => [];

export default (theme: Theme): ThemeOptions => ({
  components: {
    ServiceableNotice: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});
