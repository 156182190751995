import { Theme, ThemeOptions, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles';

// https://mui.com/customization/theme-components/#default-props
export const defaultProps = {};

// https://mui.com/customization/theme-components/#global-style-overrides
export const styleOverrides: ComponentsOverrides<Theme>['Collection'] = {
  // Set some static styles
  root: ({ theme, ownerState }) => ({
    // Default Padding
    'padding': theme.spacing(10),

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5)
    },

    // Padding if variant AND background color
    ...(!!ownerState?.variant &&
      !!ownerState?.hasBackgroundColor && {
        padding: theme.spacing(5),

        [theme.breakpoints.down('md')]: {
          padding: theme.spacing(2)
        }
      }),

    // Padding if variant AND NO background color
    ...(!!ownerState?.variant &&
      !ownerState?.hasBackgroundColor && {
        padding: 0,

        [theme.breakpoints.down('md')]: {
          padding: 0
        }
      }),

    "[class*='Collection-itemsContainer']": {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: '24px'
    }
  }),

  itemsContainer: ({ theme, itemsVariant }) => {
    return {
      width: '100%',
      maxWidth: theme.breakpoints.values.xxl,

      ...(itemsVariant === 'Pipe Divider' && {
        'display': 'flex !important',
        'width': 'unset',
        'gap': '0 !important',
        'flexDirection': 'column',

        [theme.breakpoints.up('md')]: {
          flexDirection: 'row'
        },

        '& > *': {
          'padding': theme.spacing(1.5, 3),
          'border': 'solid 1px transparent',
          'borderBottomColor': 'currentColor',

          [theme.breakpoints.up('md')]: {
            borderRight: 'solid 1px currentColor',
            borderBottomColor: 'transparent'
          },

          '&:last-child': {
            borderRightColor: 'transparent',
            borderTopColor: 'transparent'
          }
        }
      })
    };
  }
};

// https://mui.com/customization/theme-components/#adding-new-component-variants
const createVariants = (theme: Theme): ComponentsVariants['Collection'] => [
  {
    props: {
      variant: 'five-per-row'
    },
    style: {
      "[class*='Collection-itemsContainer']": {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: '24px',
        [theme.breakpoints.up('md')]: {
          gridTemplateColumns: 'repeat(3, 1fr)'
        },
        [theme.breakpoints.up('lg')]: {
          gridTemplateColumns: 'repeat(4, 1fr)'
        },
        [theme.breakpoints.up('xl')]: {
          gridTemplateColumns: 'repeat(5, 1fr)'
        }
      }
    }
  },
  {
    props: {
      variant: 'four-per-row'
    },
    style: {
      // 'padding': 0,

      // [theme.breakpoints.down('md')]: {
      //   padding: 0
      // },

      "[class*='Collection-itemsContainer']": {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: '24px',
        [theme.breakpoints.up('md')]: {
          gridTemplateColumns: 'repeat(2, 1fr)'
        },
        [theme.breakpoints.up('lg')]: {
          gridTemplateColumns: 'repeat(4, 1fr)'
        }
      }
    }
  },
  {
    props: {
      variant: 'three-per-row'
    },
    style: {
      // 'padding': 0,

      // [theme.breakpoints.down('md')]: {
      //   padding: 0
      // },

      "[class*='Collection-itemsContainer']": {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: '24px',

        [theme.breakpoints.up('md')]: {
          gridTemplateColumns: 'repeat(3, 1fr)'
        }
      }
    }
  },
  {
    props: {
      variant: 'two-per-row'
    },
    style: {
      // 'padding': 0,

      // [theme.breakpoints.down('md')]: {
      //   padding: 0
      // },

      "[class*='Collection-itemsContainer']": {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: '24px',

        [theme.breakpoints.up('md')]: {
          gridTemplateColumns: 'repeat(2, 1fr)'
        }
      }
    }
  },
  {
    props: {
      variant: 'one-per-row'
    },
    style: {
      // 'padding': 0,

      // [theme.breakpoints.down('md')]: {
      //   padding: 0
      // },

      "[class*='Collection-itemsContainer']": {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: '24px'
      }
    }
  },
  {
    props: {
      variant: 'four-per-row',
      itemsVariant: 'Broadband Labels'
    },
    style: {
      'paddingLeft': theme.spacing(2.5),
      'paddingRight': theme.spacing(2.5),
      "[class*='Collection-itemsContainer']": {
        maxWidth: theme.breakpoints.values.lg,
        gap: theme.spacing(2),
        padding: theme.spacing(0, 3),
        display: 'grid',
        gridTemplateColumns: '1fr',
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(0, 7),
          gridTemplateColumns: 'repeat(2, 1fr)',
          maxWidth: theme.breakpoints.values.lg
        },
        [theme.breakpoints.up('xl')]: {
          gridTemplateColumns: 'repeat(4, 1fr)',
          maxWidth: theme.breakpoints.values.xl,
          padding: theme.spacing(0, 12)
        }
      }
    }
  },
  {
    props: {
      variant: 'three-per-row',
      itemsVariant: 'Broadband Labels'
    },
    style: {
      'paddingLeft': theme.spacing(2.5),
      'paddingRight': theme.spacing(2.5),
      "[class*='Collection-itemsContainer']": {
        maxWidth: theme.breakpoints.values.lg,
        gap: theme.spacing(2),
        padding: theme.spacing(0, 3),
        display: 'grid',
        gridTemplateColumns: '1fr',
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(0, 7),
          gridTemplateColumns: 'repeat(2, 1fr)',
          maxWidth: theme.breakpoints.values.lg
        },
        [theme.breakpoints.up('lg')]: {
          gridTemplateColumns: 'repeat(3, 1fr)',
          maxWidth: theme.breakpoints.values.lg
        },
        [theme.breakpoints.up('xl')]: {
          gridTemplateColumns: 'repeat(3, 1fr)',
          maxWidth: theme.breakpoints.values.xl,
          padding: theme.spacing(0, 12)
        }
      }
    }
  }
];

export default (theme: Theme): ThemeOptions => ({
  components: {
    Collection: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});
