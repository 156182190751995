import type {
  Theme,
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';

// https://mui.com/customization/theme-components/#default-props
export const defaultProps: ComponentsProps['ResidentialLeadForm'] = {};

// https://mui.com/customization/theme-components/#global-style-overrides
export const styleOverrides: ComponentsOverrides<Theme>['ResidentialLeadForm'] = {
  root: ({ theme }: { theme: Theme }) => ({
    'display': 'flex',
    'flexDirection': 'column',
    'gap': theme.spacing(2),
    'padding': theme.spacing(1, 3, 1, 4),
    'maxHeight': 64,
    'overflow': 'hidden',
    'background': 'transparent',
    'transition': 'all ease 0.3s',

    '&.opened': {
      background: theme.palette.darkBlue.main,
      maxHeight: 350
    },

    [theme.breakpoints.down('md')]: {
      'paddingLeft': theme.spacing(3),
      'maxHeight': 56,

      '&.opened': {
        paddingTop: theme.spacing(2)
      }
    }
  }),
  formContent: ({ theme }: { theme: Theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5, 1)
  }),
  formGroup: ({ theme }: { theme: Theme }) => ({
    display: 'flex',
    gap: theme.spacing(1),
    flexDirection: 'column',

    [theme.breakpoints.down('md')]: {
      maxWidth: 500
    },

    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    }
  }),
  firstRow: ({ theme }: { theme: Theme }) => ({
    display: 'flex',
    gap: theme.spacing(4)
  }),
  secondRow: ({ theme }: { theme: Theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1.8fr',
    gap: theme.spacing(1)
  }),
  securityIcon: () => ({
    width: 14,
    height: 16
  }),
  customInput: ({ theme }: { theme: Theme }) => ({
    '.Mui-error': {
      outline: `2px solid ${theme.palette.red.main}`
    }
  }),
  planSelect: ({ theme }: { theme: Theme }) => ({
    '[class$=MuiOutlinedInput-input]': {
      background: theme.palette.common.white,
      borderRadius: 0,
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(1.5)
    },
    '&.Mui-error': {
      outline: `2px solid ${theme.palette.red.main}`
    },
    '[class$=MuiOutlinedInput-notchedOutline]': {
      border: 'none !important'
    },
    '[class$=MuiFilledInput-input]': {
      display: 'flex',
      alignItems: 'center'
    }
  }),
  planItem: ({ theme }: { theme: Theme }) => ({
    ...theme.typography.body4,
    display: 'flex',
    height: 48
  })
};

// https://mui.com/customization/theme-components/#adding-new-component-variants
const createVariants = (_theme: Theme): ComponentsVariants['ResidentialLeadForm'] => [];

export default (theme: Theme): ThemeOptions => ({
  components: {
    ResidentialLeadForm: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});
