import {
  type ThemeOptions,
  type ComponentsProps,
  type ComponentsOverrides,
  type ComponentsVariants,
  alpha
} from '@mui/material/styles';
import type { Theme } from '@ui/ThemeRegistry/theme.types';

const defaultProps: ComponentsProps['Media'] = {};

const styleOverrides: ComponentsOverrides<Theme>['Media'] = {};

const createVariants = (_theme: Theme): ComponentsVariants['Media'] => [];

export const mediaTheme = (theme: Theme): ThemeOptions => ({
  components: {
    Media: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});

export default mediaTheme;
