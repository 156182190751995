import type {
  Theme,
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';

// https://mui.com/customization/theme-components/#default-props
export const defaultProps: ComponentsProps['Footer'] = {};

// https://mui.com/customization/theme-components/#global-style-overrides
export const styleOverrides: ComponentsOverrides<Theme>['Footer'] = {
  root: ({ theme }: { theme: Theme }) => ({
    backgroundColor: theme.palette.grey[600],
    // maxWidth: theme.breakpoints.values.xl,
    marginLeft: 'auto',
    marginRight: 'auto'
  }),
  mainContent: () => ({
    gridColumn: '1 / 13'
  }),
  footerLinks: ({ theme }: { theme: Theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5, 0),
    gap: theme.spacing(2),

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  }),
  legalLinksContainer: ({ theme }: { theme: Theme }) => ({
    '[class*="Link-root"]': {
      color: theme.palette.common.white,
      textTransform: 'uppercase',
      padding: 0,
      height: 13,
      minWidth: 'auto'
    },
    '> :not(style)+:not(style)': {
      marginLeft: theme.spacing(1)
    }
  }),
  copyrightDisclaimerText: ({ theme }: { theme: Theme }) => ({
    'color': theme.palette.common.white,

    '& p': {
      ...theme.typography.h5
    }
  }),
  disclaimerText: ({ theme }: { theme: Theme }) => ({
    'color': theme.palette.common.white,
    'padding': theme.spacing(5, 0),

    '& p': {
      ...theme.typography.body4
    },

    '& a': {
      color: theme.palette.common.white,
      textDecoration: 'underline',
      minWidth: 0
    }
  })
};

// https://mui.com/customization/theme-components/#adding-new-component-variants
const createVariants = (_theme: Theme): ComponentsVariants['Footer'] => [];

export default (theme: Theme): ThemeOptions => ({
  components: {
    Footer: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});
