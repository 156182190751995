import type {
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';
import { Theme } from '@ui/ThemeRegistry/theme.types';

// https://mui.com/customization/theme-components/#default-props
export const defaultProps: ComponentsProps['GetOfferButton'] = {
  // contentWidth: 'xl'
};

// https://mui.com/customization/theme-components/#global-style-overrides
export const styleOverrides: ComponentsOverrides<Theme>['GetOfferButton'] = {
  root: ({ theme }) => ({
    'display': 'inline-flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    'gap': theme.spacing(0.5),

    '[class$=Link-root]': {
      minWidth: 170
    },

    '> p': {
      marginBottom: theme.spacing(1)
    }
  })
};

// https://mui.com/customization/theme-components/#adding-new-component-variants
const createVariants = (theme: Theme): ComponentsVariants['GetOfferButton'] => [
  {
    props: {
      offerActivated: true
    },
    style: {
      border: `1px solid ${theme.vars.palette.orange.main}`,
      padding: theme.spacing(1, 3),
      marginLeft: theme.spacing(-3),
      borderRadius: Number(theme.shape.borderRadius) * 2,

      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(-2),
        padding: theme.spacing(1, 2)
      }
    }
  }
];

export default (theme: Theme): ThemeOptions => ({
  components: {
    GetOfferButton: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});
