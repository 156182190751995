"use client"
import * as React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ThemeRegistry from '../ThemeRegistry/ThemeRegistry';

import { ContentModuleProvider } from '../ContentModule/ContentModuleContext';

export const AppProvider = ({ children }: { children: any }) => {
  return (
    <>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_CAPTCHA_SITE_KEY!}>
        <ThemeRegistry>
          <ContentModuleProvider>{children}</ContentModuleProvider>
        </ThemeRegistry>
      </GoogleReCaptchaProvider>
    </>
  );
};
