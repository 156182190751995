import type {
  Theme,
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';

// https://mui.com/customization/theme-components/#default-props
export const defaultProps: ComponentsProps['HomeWifi'] = {
  // contentWidth: 'xl'
};

// https://mui.com/customization/theme-components/#global-style-overrides
export const styleOverrides: ComponentsOverrides<Theme>['HomeWifi'] = {
  root: () => ({}),
  content: ({ theme }) => ({
    position: 'relative',
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7)
  }),
  backgroundRoot: () => ({
    position: 'absolute',
    zIndex: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  }),
  logo: ({ theme }) => ({
    gridColumn: '1 / 7',
    position: 'relative',
    zIndex: 1,

    img: {
      width: 144,
      height: 'auto'
    },

    [theme.breakpoints.down('lg')]: {
      gridColumn: '1 / 4'
    },

    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / 5'
    },

    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginBottom: theme.spacing(3)
    }
  }),
  card: ({ theme }) => ({
    maxWidth: 400,
    background: theme.palette.common.white,
    padding: theme.spacing(5, 2)
  }),
  cardTitle: ({ theme }) => ({
    color: theme.palette.lightBlue.main,
    textAlign: 'center',
    marginBottom: theme.spacing(2)
  }),
  rightContent: ({ theme }) => ({
    'gridColumn': '7 / 13',
    'position': 'relative',
    'zIndex': 1,
    'display': 'flex',
    'justifyContent': 'flex-end',

    '[class$=Text-root]': {
      '.MuiTypography-root': {
        display: 'block',
        marginBottom: theme.spacing(1)
      }
    },

    [theme.breakpoints.down('lg')]: {
      gridColumn: '6 / 9'
    },

    [theme.breakpoints.down('md')]: {
      gridColumn: '5 / 9'
    },

    [theme.breakpoints.down('sm')]: {
      gridColumn: '1 / 5',
      justifyContent: 'center'
    }
  })
};

// https://mui.com/customization/theme-components/#adding-new-component-variants
const createVariants = (_theme: Theme): ComponentsVariants['HomeWifi'] => [];

export default (theme: Theme): ThemeOptions => ({
  components: {
    HomeWifi: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});
