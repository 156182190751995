import type {
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';
import { Theme } from '@ui/ThemeRegistry/theme.types';

// https://mui.com/customization/theme-components/#default-props
export const defaultProps: ComponentsProps['UnorderedList'] = {};

// https://mui.com/customization/theme-components/#global-style-overrides
export const styleOverrides: ComponentsOverrides<Theme>['UnorderedList'] = {
  root: ({ theme }: { theme: Theme }) => ({
    'margin': theme.spacing(0, 0, 2),
    'listStyle': 'none',
    'paddingLeft': 0,

    'li': {
      'paddingLeft': 0,
      'display': 'flex',
      'gap': theme.spacing(1),

      '&:before': {
        display: 'inline-block',
        width: 16,
        height: 16,
        marginTop: -3,
        content: svgUrl(circleCheckIcon(theme.palette.lightBlue.main))
      },

      '> p': {
        lineHeight: 1,
        marginBottom: '16px'
      },

      '&:last-of-type > p': {
        marginBottom: 0
      }
    },
    '& li::marker': {
      content: 'none'
    }
  })
};

export const circleCheckIcon = (
  fill: string
) => `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.2 11.52L3.44 7.76L4.56 6.64L7.2 9.28L13.92 2.56C12.4 1.04 10.32 0 8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 6.48 15.6 5.12 14.88 3.92L7.2 11.52Z" fill="${fill}"/>
</svg>`;

const checkIcon = (
  fill: string
) => `<svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 11.21L0 6.21001L1.41 4.80001L5 8.38001L12.59 0.790009L14 2.21001L5 11.21Z" fill="${fill}"/>
</svg>
`;

const checkBoldIcon = (
  fill: string
) => `<svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" fill="${fill}" />
</svg>
`;

export const svgUrl = (svg: string) =>
  `url("data:image/svg+xml;base64,${Buffer.from(svg).toString('base64')}")`;

// https://mui.com/customization/theme-components/#adding-new-component-variants
const createVariants = (theme: Theme): ComponentsVariants['UnorderedList'] => [
  {
    props: {
      variant: 'default'
    },
    style: {
      li: {
        '&::marker': {
          content: '""',
          width: 4,
          height: 4,
          borderRadius: '50%',
          marginTop: 4,
          background: theme.vars.palette.grey[800]
        }
      }
    }
  },
  {
    props: {
      variant: 'lightBlueCheck'
    },
    style: {
      li: {
        '&::marker': {
          content: svgUrl(checkIcon(theme.vars.palette.lightBlue.main))
        }
      }
    }
  },
  {
    props: {
      variant: 'lightBlueCheckThick'
    },
    style: {
      li: {
        '&::marker': {
          content: svgUrl(checkBoldIcon(theme.vars.palette.lightBlue.main))
        }
      }
    }
  },
  {
    props: {
      variant: 'lightBlueCircleCheck'
    },
    style: {
      'p + &': {
        marginTop: theme.spacing(2)
      },
      'li': {
        '&::marker': {
          content: svgUrl(circleCheckIcon(theme.vars.palette.lightBlue.main))
        }
      }
    }
  },
  {
    props: {
      variant: 'orangeCircleCheck'
    },
    style: {
      li: {
        '&::marker': {
          content: svgUrl(circleCheckIcon(theme.vars.palette.lightBlue.main))
        }
      }
    }
  }
];

export default (theme: Theme): ThemeOptions => ({
  components: {
    UnorderedList: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});
