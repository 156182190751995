import type {
  Theme,
  ThemeOptions,
  ComponentsProps,
  ComponentsOverrides,
  ComponentsVariants
} from '@mui/material/styles';

// https://mui.com/customization/theme-components/#default-props
export const defaultProps: ComponentsProps['HeroResidential'] = {
  // contentWidth: 'xl'
};

// https://mui.com/customization/theme-components/#global-style-overrides
export const styleOverrides: ComponentsOverrides<Theme>['HeroResidential'] = {
  root: ({ theme }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    }
  }),
  content: () => ({
    position: 'relative',
    height: '100%'
  }),
  sideMediaRoot: ({ theme }) => ({
    position: 'absolute',
    top: 0,
    right: '50%',
    width: '50%',
    paddingRight: theme.spacing(1.5),
    bottom: 0,

    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    img: {
      objectFit: 'cover'
    }
  }),
  contentRoot: ({ theme }) => ({
    'gridRow': 1,
    'gridColumn': '7 / 13',
    'position': 'relative',
    'zIndex': 1,
    'padding': theme.spacing(8, 0, 8, 4),

    [theme.breakpoints.down('lg')]: {
      gridColumn: '5 / 9'
    },

    [theme.breakpoints.down('md')]: {
      gridRow: 'inherit',
      gridColumn: '3 / 5',
      padding: theme.spacing(4, 0)
    },

    [theme.breakpoints.down('sm')]: {
      gridColumn: '1 / 5'
    },

    '[class*=HeroResidential-text]': {
      'h1, h2, h3, h4, h5': {
        marginBottom: theme.spacing(1)
      },

      'li': {
        marginBottom: theme.spacing(1)
      }
    }
  }),
  backgroundRoot: ({ theme }) => ({
    position: 'absolute',
    zIndex: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'contain',

    // TODO: will remove later once confirmed with the designer
    // '&:after': {
    //   content: '""',
    //   position: 'absolute',
    //   top: 0,
    //   bottom: 0,
    //   width: '25%',
    //   right: 0,
    //   background: `linear-gradient(to right, transparent, ${theme.palette.grey[100]});`
    // },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
      position: 'static'

      // '&:after': {
      //   top: 'auto',
      //   height: '25%',
      //   width: '100%',
      //   background: `linear-gradient(to bottom, transparent, ${theme.palette.grey[100]});`
      // }
    }
  }),
  text: ({ theme }) => ({
    color: theme.palette.grey[600],
    h2: {
      '*': {
        fontSize: 46
      }
    },
    ul: {
      '*': {
        fontSize: 24
      }
    }
  }),
  promoMedia: ({ theme }) => ({
    maxWidth: 200,
    height: 'auto'
  }),
  actionsRoot: ({ theme }) => ({
    'marginTop': theme.spacing(3),
    'display': 'inline-flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    'gap': theme.spacing(0.5),

    '[class$=Link-root]': {
      minWidth: 170
    },

    '> p': {
      marginBottom: theme.spacing(1)
    }
  })
};

// https://mui.com/customization/theme-components/#adding-new-component-variants
const createVariants = (theme: Theme): ComponentsVariants['HeroResidential'] => [
  {
    props: {
      variant: 'imageLeftTextRight'
    },
    style: {
      '[class$=HeroResidential-contentRoot]': {
        paddingLeft: theme.spacing(4),

        [theme.breakpoints.down('md')]: {
          paddingLeft: theme.spacing(2)
        },

        [theme.breakpoints.down('sm')]: {
          paddingLeft: 0
        }
      }
    }
  },
  {
    props: {
      variant: 'textLeftImageRight'
    },
    style: {
      '[class$=HeroResidential-backgroundRoot]': {
        left: 'auto',
        right: 0

        // TODO: will remove later once confirmed with the designer
        // '&:after': {
        //   left: 0,
        //   right: 'auto',
        //   background: `linear-gradient(to left, transparent, ${theme.palette.grey[100]});`
        // },

        // [theme.breakpoints.down('md')]: {
        //   '&:after': {
        //     top: 'auto',
        //     height: '25%',
        //     width: '100%',
        //     background: `linear-gradient(to bottom, transparent, ${theme.palette.grey[100]});`
        //   }
        // }
      },
      '[class$=HeroResidential-contentRoot]': {
        gridColumn: '1 / 7',

        [theme.breakpoints.down('lg')]: {
          gridColumn: '1 / 5'
        },

        [theme.breakpoints.down('md')]: {
          gridColumn: '1 / 3'
        },

        [theme.breakpoints.down('sm')]: {
          gridColumn: '1 / 5'
        }
      },

      '[class$=HeroResidential-sideMediaRoot]': {
        right: 'auto',
        left: '50%',
        paddingRight: 0,
        paddingLeft: theme.spacing(1.5),

        [theme.breakpoints.down('sm')]: {
          padding: 0
        }
      }
    }
  }
];

export default (theme: Theme): ThemeOptions => ({
  components: {
    HeroResidential: {
      defaultProps,
      styleOverrides,
      variants: createVariants(theme)
    }
  }
});
