import { Theme, ThemeOptions, ComponentsOverrides, ComponentsVariants } from '@mui/material/styles';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// https://mui.com/customization/theme-components/#default-props
export const defaultProps = {};

// https://mui.com/customization/theme-components/#global-style-overrides
export const styleOverrides: ComponentsOverrides<Theme>['CollectionCarousel'] = {
  // Set some static styles
  root: {
    'paddingTop': '80px',
    'paddingBottom': '80px',
    '& [class*="CollectionCarousel-contentContainer"]': {
      gridTemplateColumns: 'repeat(1,1fr)'
    }
  }
  //
  // Use the ownerState to set dynamic styles
  // root: ({ ownerState, theme }) => {
  //   return {
  //     backgroundColor: ownerState.variant === 'example' ? 'red' : theme.palette.background.paper
  //   };
  // }
};

// https://mui.com/customization/theme-components/#adding-new-component-variants
const createVariants = (_theme: Theme): ComponentsVariants['CollectionCarousel'] => [
  {
    props: {
      variant: 'carousel'
    },
    style: {}
  }
];

export default (_theme: any): ThemeOptions => ({
  components: {
    CollectionCarousel: {
      defaultProps,
      styleOverrides,
      variants: createVariants(_theme)
    }
  }
});
